<template>
  <div>
    <v-alert border="top" colored-border color="secondary" elevation="2">
      <h3 class="headline">Reports</h3>
      <p>
        Your reports are included below by category.
      </p>
      <div class="text-center pa-5" v-if="loading">
        <v-progress-circular indeterminate color="primary" />
      </div>
    </v-alert>
    <div v-if="!loading">
      <div v-if="customReports && customReports.length > 0">
        <template v-for="(item, i) in customReports">
          <v-card :key="i" :loading="loading" class="mb-4">
            <v-toolbar>
              <v-toolbar-title>{{ item.text }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <div v-if="item.children">
                <template v-for="(child, j) in item.children">
                  <v-list-item :key="j" link :to="child.to" two-line>
                    <v-list-item-content>
                      <v-list-item-title dense>
                        <span>{{ child.text }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ child.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </div>
      <v-card v-else :loading="loading">
        <v-toolbar>
          <v-toolbar-title>No Reports Configured</v-toolbar-title>
        </v-toolbar>
        <v-card-text>There are currently no reports configured</v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Reports",
  metaInfo: {
    title: "Reports"
  },
  data() {
    return {
      loading: true,
      selected: null,
      customReports: []
    };
  },
  methods: {
    fetchCustomReports() {
      this.loading = true;
      return this.$api
        .get("/api/customReports/myReports")
        .then(({ data }) => {
          let reports = [];
          data
            .sort((a, b) => {
              // sort by category first, then by report
              if (a.category && b.category) {
                if (a.category.rankOrder == b.category.rankOrder) {
                  return a.rankOrder - b.rankOrder;
                } else {
                  return a.category.rankOrder - b.category.rankOrder;
                }
              } else {
                return a.rankOrder - b.rankOrder;
              }
            })
            .forEach(report => {
              if (!reports[report.category.name]) {
                reports[report.category.name] = [];
              }
              reports[report.category.name].push({
                text: report.name,
                description: report.description,
                to: {
                  name: "report",
                  params: { id: report.id }
                }
              });
            });

          this.customReports = [];
          Object.keys(reports).forEach(key => {
            this.customReports.push({
              text: key,
              children: reports[key].sort((a, b) => a.rankOrder - b.rankOrder)
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    breadcrumbs() {
      return this.$store.dispatch("setBreadcrumb", [
        {
          text: this.$i18n.translate("Dashboard"),
          to: { name: "dashboard" },
          exact: true
        },
        { text: "Reports" }
      ]);
    }
  },
  mounted() {
    this.fetchCustomReports().then(this.breadcrumbs());
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"])
  }
};
</script>
